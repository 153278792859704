import React, { useEffect, useRef, useState } from 'react';
import { io } from 'socket.io-client';

import { Logo } from './components/svg';
import { GetAdsQuery, MediaType, useGetAdsQuery } from './generated/graphql';
import { delay } from './util/time';

const App = () => {
  const videoRef = useRef(null);
  const { data } = useGetAdsQuery();
  const socket = io(process.env.REACT_APP_WEBSOCKET_URL as string);

  const [currentItem, setCurrentItem] = useState<NonNullable<NonNullable<GetAdsQuery["ads"]>["nodes"]>[0] | null>(null);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [adsPlayed, setAdsPlayed] = useState(0);

  useEffect(() => {
    if (data?.ads) {
      setCurrentItem(data?.ads?.nodes[0]);
    }
  }, [data]);

  socket.on('system', ({ action }) => {
    if (action === 'refresh') {
      window.location.reload();
    }
  });


  const processItem = (item: NonNullable<NonNullable<GetAdsQuery["ads"]>["nodes"]>[0]) => {
    if (currentItem && item.media?.duration) {
      setTimeout(() => {
        if (data?.ads) {
          if (currentItemIndex === data?.ads?.totalCount - 1) {
            setCurrentItem(data.ads.nodes[0]);
            setCurrentItemIndex(0);
          } else {
            setCurrentItem(data.ads.nodes[currentItemIndex + 1]);
            setCurrentItemIndex(currentItemIndex + 1);
          }

          setAdsPlayed(adsPlayed + 1);
          if (videoRef.current) {
            (videoRef.current as HTMLVideoElement).currentTime = 0;
            (videoRef.current as HTMLVideoElement).play();
          }
        }
      }, item.media.duration * 1000);
    }
  };

  useEffect(() => {
    if (currentItem) {
      if (adsPlayed !== 0 && adsPlayed % 5 === 0) {
        setCurrentItem(null);
        delay(10000).then(() => {
          processItem(currentItem);
        })
      } else {
        processItem(currentItem);
      }
    }
  }, [currentItem, adsPlayed]);

  return <div>
    {currentItem ?
      currentItem.media?.type === MediaType.Image ?
        <img src={currentItem.media?.url} width="100%" height="100%" alt="" className='w-screen h-screen object-cover' />
        : <video
          ref={videoRef}
          src={currentItem?.media?.url}
          autoPlay
          muted
          className="h-screen w-screen object-cover"
        />
      : <div className='w-screen h-screen flex justify-center items-center'>
        <Logo className="max-w-[50%] max-h-[50%] w-5/12 mx-auto" />
      </div>
    }
  </div>;
};

export default App;
