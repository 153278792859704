import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: { input: any; output: any; }
  /** The day, does not include a time. */
  Date: { input: any; output: any; }
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: { input: any; output: any; }
  Email: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: { input: any; output: any; }
  Url: { input: any; output: any; }
};

export type Account = Node & {
  __typename?: 'Account';
  accessToken?: Maybe<Scalars['String']['output']>;
  expiresAt?: Maybe<Scalars['Int']['output']>;
  id: Scalars['UUID']['output'];
  idToken?: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  provider: Scalars['String']['output'];
  providerAccountId: Scalars['String']['output'];
  refreshToken?: Maybe<Scalars['String']['output']>;
  scope?: Maybe<Scalars['String']['output']>;
  sessionState?: Maybe<Scalars['String']['output']>;
  tokenType?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  /** Reads a single `User` that is related to this `Account`. */
  user?: Maybe<User>;
  userId: Scalars['UUID']['output'];
};

/** A condition to be used against `Account` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type AccountCondition = {
  /** Checks for equality with the object’s `accessToken` field. */
  accessToken?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `expiresAt` field. */
  expiresAt?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `idToken` field. */
  idToken?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `provider` field. */
  provider?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `providerAccountId` field. */
  providerAccountId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `refreshToken` field. */
  refreshToken?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `scope` field. */
  scope?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `sessionState` field. */
  sessionState?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `tokenType` field. */
  tokenType?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']['input']>;
};

/** A filter to be used against `Account` object types. All fields are combined with a logical ‘and.’ */
export type AccountFilter = {
  /** Filter by the object’s `accessToken` field. */
  accessToken?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AccountFilter>>;
  /** Filter by the object’s `expiresAt` field. */
  expiresAt?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `idToken` field. */
  idToken?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AccountFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AccountFilter>>;
  /** Filter by the object’s `provider` field. */
  provider?: InputMaybe<StringFilter>;
  /** Filter by the object’s `providerAccountId` field. */
  providerAccountId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `refreshToken` field. */
  refreshToken?: InputMaybe<StringFilter>;
  /** Filter by the object’s `scope` field. */
  scope?: InputMaybe<StringFilter>;
  /** Filter by the object’s `sessionState` field. */
  sessionState?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tokenType` field. */
  tokenType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** A connection to a list of `Account` values. */
export type AccountsConnection = {
  __typename?: 'AccountsConnection';
  /** A list of edges which contains the `Account` and cursor to aid in pagination. */
  edges: Array<AccountsEdge>;
  /** A list of `Account` objects. */
  nodes: Array<Account>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Account` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Account` edge in the connection. */
export type AccountsEdge = {
  __typename?: 'AccountsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Account` at the end of the edge. */
  node: Account;
};

/** Methods to use when ordering `Account`. */
export enum AccountsOrderBy {
  AccessTokenAsc = 'ACCESS_TOKEN_ASC',
  AccessTokenDesc = 'ACCESS_TOKEN_DESC',
  ExpiresAtAsc = 'EXPIRES_AT_ASC',
  ExpiresAtDesc = 'EXPIRES_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IdTokenAsc = 'ID_TOKEN_ASC',
  IdTokenDesc = 'ID_TOKEN_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProviderAccountIdAsc = 'PROVIDER_ACCOUNT_ID_ASC',
  ProviderAccountIdDesc = 'PROVIDER_ACCOUNT_ID_DESC',
  ProviderAsc = 'PROVIDER_ASC',
  ProviderDesc = 'PROVIDER_DESC',
  RefreshTokenAsc = 'REFRESH_TOKEN_ASC',
  RefreshTokenDesc = 'REFRESH_TOKEN_DESC',
  ScopeAsc = 'SCOPE_ASC',
  ScopeDesc = 'SCOPE_DESC',
  SessionStateAsc = 'SESSION_STATE_ASC',
  SessionStateDesc = 'SESSION_STATE_DESC',
  TokenTypeAsc = 'TOKEN_TYPE_ASC',
  TokenTypeDesc = 'TOKEN_TYPE_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type Ad = Node & {
  __typename?: 'Ad';
  /** Reads and enables pagination through a set of `AdBudget`. */
  adBudgets: AdBudgetsConnection;
  createdAt: Scalars['Datetime']['output'];
  deletedAt?: Maybe<Scalars['Datetime']['output']>;
  id: Scalars['UUID']['output'];
  /** Reads a single `Media` that is related to this `Ad`. */
  media?: Maybe<Media>;
  mediaId: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  requestDetails: Scalars['JSON']['output'];
  status: AdStatus;
  updatedAt: Scalars['Datetime']['output'];
  /** Reads a single `User` that is related to this `Ad`. */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['UUID']['output']>;
};


export type AdAdBudgetsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<AdBudgetCondition>;
  filter?: InputMaybe<AdBudgetFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AdBudgetsOrderBy>>;
};

export type AdBudget = Node & {
  __typename?: 'AdBudget';
  /** Reads a single `Ad` that is related to this `AdBudget`. */
  ad?: Maybe<Ad>;
  adId?: Maybe<Scalars['UUID']['output']>;
  createdAt: Scalars['Datetime']['output'];
  dateFrom: Scalars['Date']['output'];
  dateTo: Scalars['Date']['output'];
  id: Scalars['UUID']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  price: Scalars['Int']['output'];
  updatedAt: Scalars['Datetime']['output'];
};

/**
 * A condition to be used against `AdBudget` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type AdBudgetCondition = {
  /** Checks for equality with the object’s `adId` field. */
  adId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `dateFrom` field. */
  dateFrom?: InputMaybe<Scalars['Date']['input']>;
  /** Checks for equality with the object’s `dateTo` field. */
  dateTo?: InputMaybe<Scalars['Date']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `price` field. */
  price?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A filter to be used against `AdBudget` object types. All fields are combined with a logical ‘and.’ */
export type AdBudgetFilter = {
  /** Filter by the object’s `adId` field. */
  adId?: InputMaybe<UuidFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AdBudgetFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dateFrom` field. */
  dateFrom?: InputMaybe<DateFilter>;
  /** Filter by the object’s `dateTo` field. */
  dateTo?: InputMaybe<DateFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AdBudgetFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AdBudgetFilter>>;
  /** Filter by the object’s `price` field. */
  price?: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `AdBudget` values. */
export type AdBudgetsConnection = {
  __typename?: 'AdBudgetsConnection';
  /** A list of edges which contains the `AdBudget` and cursor to aid in pagination. */
  edges: Array<AdBudgetsEdge>;
  /** A list of `AdBudget` objects. */
  nodes: Array<AdBudget>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AdBudget` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `AdBudget` edge in the connection. */
export type AdBudgetsEdge = {
  __typename?: 'AdBudgetsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `AdBudget` at the end of the edge. */
  node: AdBudget;
};

/** Methods to use when ordering `AdBudget`. */
export enum AdBudgetsOrderBy {
  AdIdAsc = 'AD_ID_ASC',
  AdIdDesc = 'AD_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DateFromAsc = 'DATE_FROM_ASC',
  DateFromDesc = 'DATE_FROM_DESC',
  DateToAsc = 'DATE_TO_ASC',
  DateToDesc = 'DATE_TO_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** A condition to be used against `Ad` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type AdCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `mediaId` field. */
  mediaId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `requestDetails` field. */
  requestDetails?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<AdStatus>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']['input']>;
};

/** A filter to be used against `Ad` object types. All fields are combined with a logical ‘and.’ */
export type AdFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AdFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `mediaId` field. */
  mediaId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AdFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AdFilter>>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<AdStatusFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

export enum AdStatus {
  Draft = 'DRAFT',
  InReview = 'IN_REVIEW',
  Published = 'PUBLISHED',
  Rejected = 'REJECTED'
}

/** A filter to be used against AdStatus fields. All fields are combined with a logical ‘and.’ */
export type AdStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<AdStatus>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<AdStatus>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<AdStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<AdStatus>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<AdStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<AdStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<AdStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<AdStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<AdStatus>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<AdStatus>>;
};

/** A connection to a list of `Ad` values. */
export type AdsConnection = {
  __typename?: 'AdsConnection';
  /** A list of edges which contains the `Ad` and cursor to aid in pagination. */
  edges: Array<AdsEdge>;
  /** A list of `Ad` objects. */
  nodes: Array<Ad>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Ad` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Ad` edge in the connection. */
export type AdsEdge = {
  __typename?: 'AdsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Ad` at the end of the edge. */
  node: Ad;
};

/** Methods to use when ordering `Ad`. */
export enum AdsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MediaIdAsc = 'MEDIA_ID_ASC',
  MediaIdDesc = 'MEDIA_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RequestDetailsAsc = 'REQUEST_DETAILS_ASC',
  RequestDetailsDesc = 'REQUEST_DETAILS_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Boolean']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Boolean']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Boolean']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Boolean']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Boolean']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Boolean']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Boolean']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

export type ClientOrganization = Node & {
  __typename?: 'ClientOrganization';
  /** Reads and enables pagination through a set of `ClientOrganizationsUser`. */
  clientOrganizationsUsers: ClientOrganizationsUsersConnection;
  createdAt: Scalars['Datetime']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  photo?: Maybe<Scalars['Url']['output']>;
  updatedAt: Scalars['Datetime']['output'];
};


export type ClientOrganizationClientOrganizationsUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ClientOrganizationsUserCondition>;
  filter?: InputMaybe<ClientOrganizationsUserFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ClientOrganizationsUsersOrderBy>>;
};

/**
 * A condition to be used against `ClientOrganization` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ClientOrganizationCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `photo` field. */
  photo?: InputMaybe<Scalars['Url']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A filter to be used against `ClientOrganization` object types. All fields are combined with a logical ‘and.’ */
export type ClientOrganizationFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ClientOrganizationFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ClientOrganizationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ClientOrganizationFilter>>;
  /** Filter by the object’s `photo` field. */
  photo?: InputMaybe<UrlFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `ClientOrganization` values. */
export type ClientOrganizationsConnection = {
  __typename?: 'ClientOrganizationsConnection';
  /** A list of edges which contains the `ClientOrganization` and cursor to aid in pagination. */
  edges: Array<ClientOrganizationsEdge>;
  /** A list of `ClientOrganization` objects. */
  nodes: Array<ClientOrganization>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ClientOrganization` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `ClientOrganization` edge in the connection. */
export type ClientOrganizationsEdge = {
  __typename?: 'ClientOrganizationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `ClientOrganization` at the end of the edge. */
  node: ClientOrganization;
};

/** Methods to use when ordering `ClientOrganization`. */
export enum ClientOrganizationsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PhotoAsc = 'PHOTO_ASC',
  PhotoDesc = 'PHOTO_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type ClientOrganizationsUser = Node & {
  __typename?: 'ClientOrganizationsUser';
  acceptedAt?: Maybe<Scalars['Datetime']['output']>;
  /** Reads a single `ClientOrganization` that is related to this `ClientOrganizationsUser`. */
  clientOrganization?: Maybe<ClientOrganization>;
  clientOrganizationId: Scalars['UUID']['output'];
  createdAt: Scalars['Datetime']['output'];
  id: Scalars['UUID']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  rejectedAt?: Maybe<Scalars['Datetime']['output']>;
  updatedAt: Scalars['Datetime']['output'];
  /** Reads a single `User` that is related to this `ClientOrganizationsUser`. */
  user?: Maybe<User>;
  userId: Scalars['UUID']['output'];
};

/**
 * A condition to be used against `ClientOrganizationsUser` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type ClientOrganizationsUserCondition = {
  /** Checks for equality with the object’s `acceptedAt` field. */
  acceptedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `clientOrganizationId` field. */
  clientOrganizationId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `rejectedAt` field. */
  rejectedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']['input']>;
};

/** A filter to be used against `ClientOrganizationsUser` object types. All fields are combined with a logical ‘and.’ */
export type ClientOrganizationsUserFilter = {
  /** Filter by the object’s `acceptedAt` field. */
  acceptedAt?: InputMaybe<DatetimeFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ClientOrganizationsUserFilter>>;
  /** Filter by the object’s `clientOrganizationId` field. */
  clientOrganizationId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ClientOrganizationsUserFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ClientOrganizationsUserFilter>>;
  /** Filter by the object’s `rejectedAt` field. */
  rejectedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** A connection to a list of `ClientOrganizationsUser` values. */
export type ClientOrganizationsUsersConnection = {
  __typename?: 'ClientOrganizationsUsersConnection';
  /** A list of edges which contains the `ClientOrganizationsUser` and cursor to aid in pagination. */
  edges: Array<ClientOrganizationsUsersEdge>;
  /** A list of `ClientOrganizationsUser` objects. */
  nodes: Array<ClientOrganizationsUser>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ClientOrganizationsUser` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `ClientOrganizationsUser` edge in the connection. */
export type ClientOrganizationsUsersEdge = {
  __typename?: 'ClientOrganizationsUsersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `ClientOrganizationsUser` at the end of the edge. */
  node: ClientOrganizationsUser;
};

/** Methods to use when ordering `ClientOrganizationsUser`. */
export enum ClientOrganizationsUsersOrderBy {
  AcceptedAtAsc = 'ACCEPTED_AT_ASC',
  AcceptedAtDesc = 'ACCEPTED_AT_DESC',
  ClientOrganizationIdAsc = 'CLIENT_ORGANIZATION_ID_ASC',
  ClientOrganizationIdDesc = 'CLIENT_ORGANIZATION_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RejectedAtAsc = 'REJECTED_AT_ASC',
  RejectedAtDesc = 'REJECTED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type ContactForm = Node & {
  __typename?: 'ContactForm';
  createdAt: Scalars['Datetime']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  email: Scalars['Email']['output'];
  id: Scalars['UUID']['output'];
  isReviewed: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  updatedAt: Scalars['Datetime']['output'];
};

/**
 * A condition to be used against `ContactForm` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ContactFormCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `data` field. */
  data?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars['Email']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `isReviewed` field. */
  isReviewed?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A filter to be used against `ContactForm` object types. All fields are combined with a logical ‘and.’ */
export type ContactFormFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ContactFormFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<EmailFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isReviewed` field. */
  isReviewed?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ContactFormFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ContactFormFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `ContactForm` values. */
export type ContactFormsConnection = {
  __typename?: 'ContactFormsConnection';
  /** A list of edges which contains the `ContactForm` and cursor to aid in pagination. */
  edges: Array<ContactFormsEdge>;
  /** A list of `ContactForm` objects. */
  nodes: Array<ContactForm>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ContactForm` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `ContactForm` edge in the connection. */
export type ContactFormsEdge = {
  __typename?: 'ContactFormsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `ContactForm` at the end of the edge. */
  node: ContactForm;
};

/** Methods to use when ordering `ContactForm`. */
export enum ContactFormsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DataAsc = 'DATA_ASC',
  DataDesc = 'DATA_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsReviewedAsc = 'IS_REVIEWED_ASC',
  IsReviewedDesc = 'IS_REVIEWED_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** All input for the `createAd` mutation. */
export type CreateAdInput = {
  adDuration?: InputMaybe<Scalars['String']['input']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mediaDuration?: InputMaybe<Scalars['Int']['input']>;
  mediaType?: InputMaybe<MediaType>;
  mediaUrl?: InputMaybe<Scalars['Url']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Date']['input']>;
};

/** The output of our `createAd` mutation. */
export type CreateAdPayload = {
  __typename?: 'CreateAdPayload';
  ad?: Maybe<Ad>;
  /** An edge for our `Ad`. May be used by Relay 1. */
  adEdge?: Maybe<AdsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Media` that is related to this `Ad`. */
  media?: Maybe<Media>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Ad`. */
  user?: Maybe<User>;
};


/** The output of our `createAd` mutation. */
export type CreateAdPayloadAdEdgeArgs = {
  orderBy?: InputMaybe<Array<AdsOrderBy>>;
};

/** A filter to be used against Date fields. All fields are combined with a logical ‘and.’ */
export type DateFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Date']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Date']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Date']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Date']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Date']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Date']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Date']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Date']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Date']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Date']['input']>>;
};

/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Datetime']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Datetime']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Datetime']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Datetime']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Datetime']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Datetime']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Datetime']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Datetime']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Datetime']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Datetime']['input']>>;
};

/** All input for the `deleteAd` mutation. */
export type DeleteAdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
};

/** The output of our `deleteAd` mutation. */
export type DeleteAdPayload = {
  __typename?: 'DeleteAdPayload';
  ad?: Maybe<Ad>;
  /** An edge for our `Ad`. May be used by Relay 1. */
  adEdge?: Maybe<AdsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Media` that is related to this `Ad`. */
  media?: Maybe<Media>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Ad`. */
  user?: Maybe<User>;
};


/** The output of our `deleteAd` mutation. */
export type DeleteAdPayloadAdEdgeArgs = {
  orderBy?: InputMaybe<Array<AdsOrderBy>>;
};

/** A filter to be used against Email fields. All fields are combined with a logical ‘and.’ */
export type EmailFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Email']['input']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: InputMaybe<Scalars['Email']['input']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: InputMaybe<Scalars['Email']['input']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: InputMaybe<Scalars['Email']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Email']['input']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: InputMaybe<Scalars['Email']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Email']['input']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: InputMaybe<Scalars['Email']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Email']['input']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: InputMaybe<Scalars['Email']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Email']['input']>>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: InputMaybe<Array<Scalars['Email']['input']>>;
  /** Contains the specified string (case-sensitive). */
  includes?: InputMaybe<Scalars['Email']['input']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: InputMaybe<Scalars['Email']['input']>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Email']['input']>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: InputMaybe<Scalars['Email']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Email']['input']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: InputMaybe<Scalars['Email']['input']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: InputMaybe<Scalars['Email']['input']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: InputMaybe<Scalars['Email']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Email']['input']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: InputMaybe<Scalars['Email']['input']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: InputMaybe<Scalars['Email']['input']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: InputMaybe<Scalars['Email']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Email']['input']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: InputMaybe<Scalars['Email']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Email']['input']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: InputMaybe<Array<Scalars['Email']['input']>>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: InputMaybe<Scalars['Email']['input']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: InputMaybe<Scalars['Email']['input']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: InputMaybe<Scalars['Email']['input']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: InputMaybe<Scalars['Email']['input']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: InputMaybe<Scalars['Email']['input']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: InputMaybe<Scalars['Email']['input']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: InputMaybe<Scalars['Email']['input']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: InputMaybe<Scalars['Email']['input']>;
};

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Int']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Int']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Int']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Int']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Int']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Int']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Int']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Int']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type Media = Node & {
  __typename?: 'Media';
  /** Reads and enables pagination through a set of `Ad`. */
  ads: AdsConnection;
  createdAt: Scalars['Datetime']['output'];
  duration?: Maybe<Scalars['Int']['output']>;
  id: Scalars['UUID']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  type: MediaType;
  url: Scalars['Url']['output'];
  /** Reads a single `User` that is related to this `Media`. */
  user?: Maybe<User>;
  userId: Scalars['UUID']['output'];
};


export type MediaAdsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<AdCondition>;
  filter?: InputMaybe<AdFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AdsOrderBy>>;
};

/** A condition to be used against `Media` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type MediaCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `duration` field. */
  duration?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<MediaType>;
  /** Checks for equality with the object’s `url` field. */
  url?: InputMaybe<Scalars['Url']['input']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']['input']>;
};

/** A connection to a list of `Media` values. */
export type MediaConnection = {
  __typename?: 'MediaConnection';
  /** A list of edges which contains the `Media` and cursor to aid in pagination. */
  edges: Array<MediaEdge>;
  /** A list of `Media` objects. */
  nodes: Array<Media>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Media` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Media` edge in the connection. */
export type MediaEdge = {
  __typename?: 'MediaEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Media` at the end of the edge. */
  node: Media;
};

/** A filter to be used against `Media` object types. All fields are combined with a logical ‘and.’ */
export type MediaFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MediaFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `duration` field. */
  duration?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MediaFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MediaFilter>>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<MediaTypeFilter>;
  /** Filter by the object’s `url` field. */
  url?: InputMaybe<UrlFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** Methods to use when ordering `Media`. */
export enum MediaOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DurationAsc = 'DURATION_ASC',
  DurationDesc = 'DURATION_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UrlAsc = 'URL_ASC',
  UrlDesc = 'URL_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export enum MediaType {
  Image = 'IMAGE',
  Video = 'VIDEO'
}

/** A filter to be used against MediaType fields. All fields are combined with a logical ‘and.’ */
export type MediaTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<MediaType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<MediaType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<MediaType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<MediaType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<MediaType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<MediaType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<MediaType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<MediaType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<MediaType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<MediaType>>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation';
  createAd?: Maybe<CreateAdPayload>;
  deleteAd?: Maybe<DeleteAdPayload>;
  publishAd?: Maybe<PublishAdPayload>;
  rejectAd?: Maybe<RejectAdPayload>;
  submitForm?: Maybe<SubmitFormPayload>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAdArgs = {
  input: CreateAdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAdArgs = {
  input: DeleteAdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationPublishAdArgs = {
  input: PublishAdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRejectAdArgs = {
  input: RejectAdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSubmitFormArgs = {
  input: SubmitFormInput;
};

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']['output']>;
};

/** All input for the `publishAd` mutation. */
export type PublishAdInput = {
  adId?: InputMaybe<Scalars['UUID']['input']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `publishAd` mutation. */
export type PublishAdPayload = {
  __typename?: 'PublishAdPayload';
  ad?: Maybe<Ad>;
  /** An edge for our `Ad`. May be used by Relay 1. */
  adEdge?: Maybe<AdsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Media` that is related to this `Ad`. */
  media?: Maybe<Media>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Ad`. */
  user?: Maybe<User>;
};


/** The output of our `publishAd` mutation. */
export type PublishAdPayloadAdEdgeArgs = {
  orderBy?: InputMaybe<Array<AdsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: 'Query';
  account?: Maybe<Account>;
  /** Reads a single `Account` using its globally unique `ID`. */
  accountByNodeId?: Maybe<Account>;
  /** Reads and enables pagination through a set of `Account`. */
  accounts?: Maybe<AccountsConnection>;
  ad?: Maybe<Ad>;
  adBelongsToUser?: Maybe<Scalars['Boolean']['output']>;
  adBudget?: Maybe<AdBudget>;
  /** Reads a single `AdBudget` using its globally unique `ID`. */
  adBudgetByNodeId?: Maybe<AdBudget>;
  /** Reads and enables pagination through a set of `AdBudget`. */
  adBudgets?: Maybe<AdBudgetsConnection>;
  /** Reads a single `Ad` using its globally unique `ID`. */
  adByNodeId?: Maybe<Ad>;
  /** Reads and enables pagination through a set of `Ad`. */
  ads?: Maybe<AdsConnection>;
  clientOrganization?: Maybe<ClientOrganization>;
  /** Reads a single `ClientOrganization` using its globally unique `ID`. */
  clientOrganizationByNodeId?: Maybe<ClientOrganization>;
  /** Reads and enables pagination through a set of `ClientOrganization`. */
  clientOrganizations?: Maybe<ClientOrganizationsConnection>;
  clientOrganizationsUser?: Maybe<ClientOrganizationsUser>;
  /** Reads a single `ClientOrganizationsUser` using its globally unique `ID`. */
  clientOrganizationsUserByNodeId?: Maybe<ClientOrganizationsUser>;
  /** Reads and enables pagination through a set of `ClientOrganizationsUser`. */
  clientOrganizationsUsers?: Maybe<ClientOrganizationsUsersConnection>;
  contactForm?: Maybe<ContactForm>;
  /** Reads a single `ContactForm` using its globally unique `ID`. */
  contactFormByNodeId?: Maybe<ContactForm>;
  /** Reads and enables pagination through a set of `ContactForm`. */
  contactForms?: Maybe<ContactFormsConnection>;
  currentUserId?: Maybe<Scalars['UUID']['output']>;
  media?: Maybe<Media>;
  /** Reads a single `Media` using its globally unique `ID`. */
  mediaByNodeId?: Maybe<Media>;
  /** Reads and enables pagination through a set of `Media`. */
  medias?: Maybe<MediaConnection>;
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars['ID']['output'];
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  role?: Maybe<Role>;
  /** Reads a single `Role` using its globally unique `ID`. */
  roleByNodeId?: Maybe<Role>;
  /** Reads and enables pagination through a set of `Role`. */
  roles?: Maybe<RolesConnection>;
  session?: Maybe<Session>;
  /** Reads a single `Session` using its globally unique `ID`. */
  sessionByNodeId?: Maybe<Session>;
  /** Reads and enables pagination through a set of `Session`. */
  sessions?: Maybe<SessionsConnection>;
  taxiOrganization?: Maybe<TaxiOrganization>;
  /** Reads a single `TaxiOrganization` using its globally unique `ID`. */
  taxiOrganizationByNodeId?: Maybe<TaxiOrganization>;
  /** Reads and enables pagination through a set of `TaxiOrganization`. */
  taxiOrganizations?: Maybe<TaxiOrganizationsConnection>;
  /** Reads and enables pagination through a set of `Taxy`. */
  taxies?: Maybe<TaxiesConnection>;
  taxy?: Maybe<Taxy>;
  /** Reads a single `Taxy` using its globally unique `ID`. */
  taxyByNodeId?: Maybe<Taxy>;
  user?: Maybe<User>;
  userByEmail?: Maybe<User>;
  /** Reads a single `User` using its globally unique `ID`. */
  userByNodeId?: Maybe<User>;
  userIsAdmin?: Maybe<Scalars['Boolean']['output']>;
  userRole?: Maybe<UserRole>;
  /** Reads a single `UserRole` using its globally unique `ID`. */
  userRoleByNodeId?: Maybe<UserRole>;
  /** Reads and enables pagination through a set of `UserRole`. */
  userRoles?: Maybe<UserRolesConnection>;
  /** Reads and enables pagination through a set of `User`. */
  users?: Maybe<UsersConnection>;
  /** Reads and enables pagination through a set of `VerificationToken`. */
  verificationTokens?: Maybe<VerificationTokensConnection>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAccountArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAccountByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAccountsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<AccountCondition>;
  filter?: InputMaybe<AccountFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccountsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAdBelongsToUserArgs = {
  adId?: InputMaybe<Scalars['UUID']['input']>;
  userId?: InputMaybe<Scalars['UUID']['input']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdBudgetArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAdBudgetByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAdBudgetsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<AdBudgetCondition>;
  filter?: InputMaybe<AdBudgetFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AdBudgetsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAdsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<AdCondition>;
  filter?: InputMaybe<AdFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AdsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryClientOrganizationArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryClientOrganizationByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryClientOrganizationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ClientOrganizationCondition>;
  filter?: InputMaybe<ClientOrganizationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ClientOrganizationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryClientOrganizationsUserArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryClientOrganizationsUserByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryClientOrganizationsUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ClientOrganizationsUserCondition>;
  filter?: InputMaybe<ClientOrganizationsUserFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ClientOrganizationsUsersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryContactFormArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryContactFormByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryContactFormsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ContactFormCondition>;
  filter?: InputMaybe<ContactFormFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactFormsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMediaArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMediaByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMediasArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<MediaCondition>;
  filter?: InputMaybe<MediaFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MediaOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRoleArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRoleByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRolesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RoleCondition>;
  filter?: InputMaybe<RoleFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RolesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySessionArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySessionByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySessionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<SessionCondition>;
  filter?: InputMaybe<SessionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SessionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTaxiOrganizationArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTaxiOrganizationByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTaxiOrganizationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TaxiOrganizationCondition>;
  filter?: InputMaybe<TaxiOrganizationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaxiOrganizationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTaxiesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TaxyCondition>;
  filter?: InputMaybe<TaxyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaxiesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTaxyArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTaxyByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserByEmailArgs = {
  email: Scalars['Email']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserRoleArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserRoleByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserRolesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserRoleCondition>;
  filter?: InputMaybe<UserRoleFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserRolesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryVerificationTokensArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<VerificationTokenCondition>;
  filter?: InputMaybe<VerificationTokenFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<VerificationTokensOrderBy>>;
};

/** All input for the `rejectAd` mutation. */
export type RejectAdInput = {
  adId?: InputMaybe<Scalars['UUID']['input']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `rejectAd` mutation. */
export type RejectAdPayload = {
  __typename?: 'RejectAdPayload';
  ad?: Maybe<Ad>;
  /** An edge for our `Ad`. May be used by Relay 1. */
  adEdge?: Maybe<AdsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Media` that is related to this `Ad`. */
  media?: Maybe<Media>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Ad`. */
  user?: Maybe<User>;
};


/** The output of our `rejectAd` mutation. */
export type RejectAdPayloadAdEdgeArgs = {
  orderBy?: InputMaybe<Array<AdsOrderBy>>;
};

export type Role = Node & {
  __typename?: 'Role';
  createdAt: Scalars['Datetime']['output'];
  deletedAt?: Maybe<Scalars['Datetime']['output']>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  updatedAt: Scalars['Datetime']['output'];
  /** Reads and enables pagination through a set of `UserRole`. */
  userRoles: UserRolesConnection;
};


export type RoleUserRolesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserRoleCondition>;
  filter?: InputMaybe<UserRoleFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserRolesOrderBy>>;
};

/** A condition to be used against `Role` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type RoleCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A filter to be used against `Role` object types. All fields are combined with a logical ‘and.’ */
export type RoleFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<RoleFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<RoleFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<RoleFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `Role` values. */
export type RolesConnection = {
  __typename?: 'RolesConnection';
  /** A list of edges which contains the `Role` and cursor to aid in pagination. */
  edges: Array<RolesEdge>;
  /** A list of `Role` objects. */
  nodes: Array<Role>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Role` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Role` edge in the connection. */
export type RolesEdge = {
  __typename?: 'RolesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Role` at the end of the edge. */
  node: Role;
};

/** Methods to use when ordering `Role`. */
export enum RolesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type Session = Node & {
  __typename?: 'Session';
  expires: Scalars['Datetime']['output'];
  id: Scalars['UUID']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  sessionToken: Scalars['String']['output'];
  /** Reads a single `User` that is related to this `Session`. */
  user?: Maybe<User>;
  userId: Scalars['UUID']['output'];
};

/** A condition to be used against `Session` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type SessionCondition = {
  /** Checks for equality with the object’s `expires` field. */
  expires?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `sessionToken` field. */
  sessionToken?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']['input']>;
};

/** A filter to be used against `Session` object types. All fields are combined with a logical ‘and.’ */
export type SessionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SessionFilter>>;
  /** Filter by the object’s `expires` field. */
  expires?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SessionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SessionFilter>>;
  /** Filter by the object’s `sessionToken` field. */
  sessionToken?: InputMaybe<StringFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** A connection to a list of `Session` values. */
export type SessionsConnection = {
  __typename?: 'SessionsConnection';
  /** A list of edges which contains the `Session` and cursor to aid in pagination. */
  edges: Array<SessionsEdge>;
  /** A list of `Session` objects. */
  nodes: Array<Session>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Session` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Session` edge in the connection. */
export type SessionsEdge = {
  __typename?: 'SessionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Session` at the end of the edge. */
  node: Session;
};

/** Methods to use when ordering `Session`. */
export enum SessionsOrderBy {
  ExpiresAsc = 'EXPIRES_ASC',
  ExpiresDesc = 'EXPIRES_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SessionTokenAsc = 'SESSION_TOKEN_ASC',
  SessionTokenDesc = 'SESSION_TOKEN_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['String']['input']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: InputMaybe<Scalars['String']['input']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['String']['input']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['String']['input']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['String']['input']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Contains the specified string (case-sensitive). */
  includes?: InputMaybe<Scalars['String']['input']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['String']['input']>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['String']['input']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: InputMaybe<Scalars['String']['input']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['String']['input']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: InputMaybe<Scalars['String']['input']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['String']['input']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: InputMaybe<Scalars['String']['input']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: InputMaybe<Scalars['String']['input']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: InputMaybe<Scalars['String']['input']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: InputMaybe<Scalars['String']['input']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: InputMaybe<Scalars['String']['input']>;
};

/** All input for the `submitForm` mutation. */
export type SubmitFormInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['Email']['input']>;
  message?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `submitForm` mutation. */
export type SubmitFormPayload = {
  __typename?: 'SubmitFormPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  contactForm?: Maybe<ContactForm>;
  /** An edge for our `ContactForm`. May be used by Relay 1. */
  contactFormEdge?: Maybe<ContactFormsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our `submitForm` mutation. */
export type SubmitFormPayloadContactFormEdgeArgs = {
  orderBy?: InputMaybe<Array<ContactFormsOrderBy>>;
};

export type TaxiOrganization = Node & {
  __typename?: 'TaxiOrganization';
  createdAt: Scalars['Datetime']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  photo?: Maybe<Scalars['Url']['output']>;
  /** Reads and enables pagination through a set of `Taxy`. */
  taxies: TaxiesConnection;
  updatedAt: Scalars['Datetime']['output'];
};


export type TaxiOrganizationTaxiesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TaxyCondition>;
  filter?: InputMaybe<TaxyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaxiesOrderBy>>;
};

/**
 * A condition to be used against `TaxiOrganization` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TaxiOrganizationCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `photo` field. */
  photo?: InputMaybe<Scalars['Url']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A filter to be used against `TaxiOrganization` object types. All fields are combined with a logical ‘and.’ */
export type TaxiOrganizationFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TaxiOrganizationFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TaxiOrganizationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TaxiOrganizationFilter>>;
  /** Filter by the object’s `photo` field. */
  photo?: InputMaybe<UrlFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `TaxiOrganization` values. */
export type TaxiOrganizationsConnection = {
  __typename?: 'TaxiOrganizationsConnection';
  /** A list of edges which contains the `TaxiOrganization` and cursor to aid in pagination. */
  edges: Array<TaxiOrganizationsEdge>;
  /** A list of `TaxiOrganization` objects. */
  nodes: Array<TaxiOrganization>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TaxiOrganization` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `TaxiOrganization` edge in the connection. */
export type TaxiOrganizationsEdge = {
  __typename?: 'TaxiOrganizationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `TaxiOrganization` at the end of the edge. */
  node: TaxiOrganization;
};

/** Methods to use when ordering `TaxiOrganization`. */
export enum TaxiOrganizationsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PhotoAsc = 'PHOTO_ASC',
  PhotoDesc = 'PHOTO_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** A connection to a list of `Taxy` values. */
export type TaxiesConnection = {
  __typename?: 'TaxiesConnection';
  /** A list of edges which contains the `Taxy` and cursor to aid in pagination. */
  edges: Array<TaxiesEdge>;
  /** A list of `Taxy` objects. */
  nodes: Array<Taxy>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Taxy` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Taxy` edge in the connection. */
export type TaxiesEdge = {
  __typename?: 'TaxiesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Taxy` at the end of the edge. */
  node: Taxy;
};

/** Methods to use when ordering `Taxy`. */
export enum TaxiesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PiIdAsc = 'PI_ID_ASC',
  PiIdDesc = 'PI_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TaxiOrganizationIdAsc = 'TAXI_ORGANIZATION_ID_ASC',
  TaxiOrganizationIdDesc = 'TAXI_ORGANIZATION_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type Taxy = Node & {
  __typename?: 'Taxy';
  createdAt: Scalars['Datetime']['output'];
  id: Scalars['UUID']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  piId: Scalars['String']['output'];
  /** Reads a single `TaxiOrganization` that is related to this `Taxy`. */
  taxiOrganization?: Maybe<TaxiOrganization>;
  taxiOrganizationId?: Maybe<Scalars['UUID']['output']>;
  updatedAt: Scalars['Datetime']['output'];
};

/** A condition to be used against `Taxy` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type TaxyCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `piId` field. */
  piId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `taxiOrganizationId` field. */
  taxiOrganizationId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A filter to be used against `Taxy` object types. All fields are combined with a logical ‘and.’ */
export type TaxyFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TaxyFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TaxyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TaxyFilter>>;
  /** Filter by the object’s `piId` field. */
  piId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `taxiOrganizationId` field. */
  taxiOrganizationId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['UUID']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['UUID']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['UUID']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['UUID']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['UUID']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['UUID']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['UUID']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['UUID']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

/** A filter to be used against Url fields. All fields are combined with a logical ‘and.’ */
export type UrlFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Url']['input']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: InputMaybe<Scalars['Url']['input']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: InputMaybe<Scalars['Url']['input']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: InputMaybe<Scalars['Url']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Url']['input']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: InputMaybe<Scalars['Url']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Url']['input']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: InputMaybe<Scalars['Url']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Url']['input']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: InputMaybe<Scalars['Url']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Url']['input']>>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: InputMaybe<Array<Scalars['Url']['input']>>;
  /** Contains the specified string (case-sensitive). */
  includes?: InputMaybe<Scalars['Url']['input']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: InputMaybe<Scalars['Url']['input']>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Url']['input']>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: InputMaybe<Scalars['Url']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Url']['input']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: InputMaybe<Scalars['Url']['input']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: InputMaybe<Scalars['Url']['input']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: InputMaybe<Scalars['Url']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Url']['input']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: InputMaybe<Scalars['Url']['input']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: InputMaybe<Scalars['Url']['input']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: InputMaybe<Scalars['Url']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Url']['input']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: InputMaybe<Scalars['Url']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Url']['input']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: InputMaybe<Array<Scalars['Url']['input']>>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: InputMaybe<Scalars['Url']['input']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: InputMaybe<Scalars['Url']['input']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: InputMaybe<Scalars['Url']['input']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: InputMaybe<Scalars['Url']['input']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: InputMaybe<Scalars['Url']['input']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: InputMaybe<Scalars['Url']['input']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: InputMaybe<Scalars['Url']['input']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: InputMaybe<Scalars['Url']['input']>;
};

export type User = Node & {
  __typename?: 'User';
  /** Reads and enables pagination through a set of `Account`. */
  accounts: AccountsConnection;
  /** Reads and enables pagination through a set of `Ad`. */
  ads: AdsConnection;
  /** Reads and enables pagination through a set of `ClientOrganizationsUser`. */
  clientOrganizationsUsers: ClientOrganizationsUsersConnection;
  createdAt: Scalars['Datetime']['output'];
  deletedAt?: Maybe<Scalars['Datetime']['output']>;
  email?: Maybe<Scalars['Email']['output']>;
  emailVerifiedAt?: Maybe<Scalars['Datetime']['output']>;
  id: Scalars['UUID']['output'];
  image?: Maybe<Scalars['Url']['output']>;
  /** Reads and enables pagination through a set of `Media`. */
  medias: MediaConnection;
  name?: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads and enables pagination through a set of `Session`. */
  sessions: SessionsConnection;
  updatedAt: Scalars['Datetime']['output'];
  /** Reads and enables pagination through a set of `UserRole`. */
  userRoles: UserRolesConnection;
};


export type UserAccountsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<AccountCondition>;
  filter?: InputMaybe<AccountFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccountsOrderBy>>;
};


export type UserAdsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<AdCondition>;
  filter?: InputMaybe<AdFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AdsOrderBy>>;
};


export type UserClientOrganizationsUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ClientOrganizationsUserCondition>;
  filter?: InputMaybe<ClientOrganizationsUserFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ClientOrganizationsUsersOrderBy>>;
};


export type UserMediasArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<MediaCondition>;
  filter?: InputMaybe<MediaFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MediaOrderBy>>;
};


export type UserSessionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<SessionCondition>;
  filter?: InputMaybe<SessionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SessionsOrderBy>>;
};


export type UserUserRolesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserRoleCondition>;
  filter?: InputMaybe<UserRoleFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserRolesOrderBy>>;
};

/** A condition to be used against `User` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type UserCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars['Email']['input']>;
  /** Checks for equality with the object’s `emailVerifiedAt` field. */
  emailVerifiedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `image` field. */
  image?: InputMaybe<Scalars['Url']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A filter to be used against `User` object types. All fields are combined with a logical ‘and.’ */
export type UserFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<EmailFilter>;
  /** Filter by the object’s `emailVerifiedAt` field. */
  emailVerifiedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `image` field. */
  image?: InputMaybe<UrlFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

export type UserRole = Node & {
  __typename?: 'UserRole';
  id: Scalars['UUID']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `Role` that is related to this `UserRole`. */
  role?: Maybe<Role>;
  roleId: Scalars['UUID']['output'];
  /** Reads a single `User` that is related to this `UserRole`. */
  user?: Maybe<User>;
  userId: Scalars['UUID']['output'];
};

/**
 * A condition to be used against `UserRole` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type UserRoleCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `roleId` field. */
  roleId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']['input']>;
};

/** A filter to be used against `UserRole` object types. All fields are combined with a logical ‘and.’ */
export type UserRoleFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserRoleFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserRoleFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserRoleFilter>>;
  /** Filter by the object’s `roleId` field. */
  roleId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** A connection to a list of `UserRole` values. */
export type UserRolesConnection = {
  __typename?: 'UserRolesConnection';
  /** A list of edges which contains the `UserRole` and cursor to aid in pagination. */
  edges: Array<UserRolesEdge>;
  /** A list of `UserRole` objects. */
  nodes: Array<UserRole>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserRole` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `UserRole` edge in the connection. */
export type UserRolesEdge = {
  __typename?: 'UserRolesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `UserRole` at the end of the edge. */
  node: UserRole;
};

/** Methods to use when ordering `UserRole`. */
export enum UserRolesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RoleIdAsc = 'ROLE_ID_ASC',
  RoleIdDesc = 'ROLE_ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** A connection to a list of `User` values. */
export type UsersConnection = {
  __typename?: 'UsersConnection';
  /** A list of edges which contains the `User` and cursor to aid in pagination. */
  edges: Array<UsersEdge>;
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `User` edge in the connection. */
export type UsersEdge = {
  __typename?: 'UsersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: User;
};

/** Methods to use when ordering `User`. */
export enum UsersOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  EmailVerifiedAtAsc = 'EMAIL_VERIFIED_AT_ASC',
  EmailVerifiedAtDesc = 'EMAIL_VERIFIED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ImageAsc = 'IMAGE_ASC',
  ImageDesc = 'IMAGE_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type VerificationToken = {
  __typename?: 'VerificationToken';
  expires: Scalars['Datetime']['output'];
  identifier: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

/**
 * A condition to be used against `VerificationToken` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type VerificationTokenCondition = {
  /** Checks for equality with the object’s `expires` field. */
  expires?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `identifier` field. */
  identifier?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `token` field. */
  token?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `VerificationToken` object types. All fields are combined with a logical ‘and.’ */
export type VerificationTokenFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<VerificationTokenFilter>>;
  /** Filter by the object’s `expires` field. */
  expires?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `identifier` field. */
  identifier?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<VerificationTokenFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<VerificationTokenFilter>>;
  /** Filter by the object’s `token` field. */
  token?: InputMaybe<StringFilter>;
};

/** A connection to a list of `VerificationToken` values. */
export type VerificationTokensConnection = {
  __typename?: 'VerificationTokensConnection';
  /** A list of edges which contains the `VerificationToken` and cursor to aid in pagination. */
  edges: Array<VerificationTokensEdge>;
  /** A list of `VerificationToken` objects. */
  nodes: Array<VerificationToken>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `VerificationToken` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `VerificationToken` edge in the connection. */
export type VerificationTokensEdge = {
  __typename?: 'VerificationTokensEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `VerificationToken` at the end of the edge. */
  node: VerificationToken;
};

/** Methods to use when ordering `VerificationToken`. */
export enum VerificationTokensOrderBy {
  ExpiresAsc = 'EXPIRES_ASC',
  ExpiresDesc = 'EXPIRES_DESC',
  IdentifierAsc = 'IDENTIFIER_ASC',
  IdentifierDesc = 'IDENTIFIER_DESC',
  Natural = 'NATURAL',
  TokenAsc = 'TOKEN_ASC',
  TokenDesc = 'TOKEN_DESC'
}

export type GetAdsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdsQuery = { __typename?: 'Query', ads?: { __typename?: 'AdsConnection', totalCount: number, nodes: Array<{ __typename?: 'Ad', id: any, status: AdStatus, media?: { __typename?: 'Media', id: any, url: any, type: MediaType, duration?: number | null } | null }> } | null };


export const GetAdsDocument = gql`
    query GetAds {
  ads {
    nodes {
      id
      media {
        id
        url
        type
        duration
      }
      status
    }
    totalCount
  }
}
    `;

/**
 * __useGetAdsQuery__
 *
 * To run a query within a React component, call `useGetAdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdsQuery(baseOptions?: Apollo.QueryHookOptions<GetAdsQuery, GetAdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdsQuery, GetAdsQueryVariables>(GetAdsDocument, options);
      }
export function useGetAdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdsQuery, GetAdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdsQuery, GetAdsQueryVariables>(GetAdsDocument, options);
        }
export function useGetAdsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAdsQuery, GetAdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAdsQuery, GetAdsQueryVariables>(GetAdsDocument, options);
        }
export type GetAdsQueryHookResult = ReturnType<typeof useGetAdsQuery>;
export type GetAdsLazyQueryHookResult = ReturnType<typeof useGetAdsLazyQuery>;
export type GetAdsSuspenseQueryHookResult = ReturnType<typeof useGetAdsSuspenseQuery>;
export type GetAdsQueryResult = Apollo.QueryResult<GetAdsQuery, GetAdsQueryVariables>;